/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish -> Thanks Paul!
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ======================================================================== */

(function($) {

  var Sage = {
    'common': {
      init: function() {
        

        // // Function called if AdBlock is not detected
        // function adBlockNotDetected() {
            
        // }
        // // Function called if AdBlock is detected
        // function adBlockDetected() {
        //     $('.adspace').hide();
        // }
        // if(typeof blockAdBlock === 'undefined') {
        //     adBlockDetected();
        // } else {
        //     blockAdBlock.onDetected(adBlockDetected);
        //     blockAdBlock.onNotDetected(adBlockNotDetected);
        // }

        // infinite-ish scrolling
        $('#infinite').infinitescroll({
            navSelector: ".navigation",
            nextSelector: ".nav-previous a",
            itemSelector: ".latest",
            debug: false,
            dataType: 'html',
            behavior: 'twitter'
        });

        $(window).unbind('.infscr');

        $('.nav-previous a').click(function(e){
            //$(document).trigger('retrieve.infscr');
            $('#infinite').infinitescroll('retrieve');
            return false;
        });

        $(document).ajaxError(function(e,xhr,opt){
            if(xhr.status===404){
                $('.nav-previous a').remove();
            }
        });

        // mobile menu
        $('.mobile-toggle').click(function(){

            $('.cmn-toggle-switch', this).toggleClass('active');
            $('html').toggleClass('menu-open');

            if( $('.cmn-toggle-switch').hasClass('active') ){
                $('.navbar-collapse').css( 'height', $(window).height()-$('.brand-wrapper').height() );
                $('body,html').css( 'height', $(window).height() );
            } else {
                $('.navbar-collapse').css( 'height', 'auto' );
            }

            $('.dropdown-menu').removeClass('active');
            $('.caret').removeClass('active');

        });

        var mainSwiper = new Swiper ('.swiper-container', {
          effect: "fade",
          loop: true,
          calculateHeight: true,
          sliderPerView: 1,
          loopedSlides: 1
        });

        var thumbSwiper = new Swiper ('.swiper-container-thumbs', {
          loop: true,
          direction: 'vertical',
          slidesPerView: 3,
          loopedSlides: 3,
          autoplay: 8000,
          watchSlidesProgress: true,
          nextButton: '.swiper-button-next',
          prevButton: '.swiper-button-prev',
          onSlideChangeStart: function(swiper){
            $('.timer').remove();
            var slide = $('.swiper-container-thumbs .swiper-slide-active'),
                currentIndex = slide.data('index');

            mainSwiper.slideTo(currentIndex+1);

            slide.append('<div class="timer"></div>');

                var timer = $('.timer'),
                start = new Date(),
                maxTime = 8000,
                timeoutVal = Math.floor(maxTime/100);

                timer.css("width", "0");

            function updateProgress(percentage) {
                timer.css("width", percentage + "%");
            }

            function animateUpdate() {
                var now = new Date();
                var timeDiff = now.getTime() - start.getTime();
                var perc = Math.round((timeDiff/maxTime)*100);
                
                if (perc <= 100) {
                   updateProgress(perc);
                   setTimeout(animateUpdate, timeoutVal);
                }
            }

            animateUpdate(timer);

          }
        });

        //mainSwiper.params.control = thumbSwiper;
//        thumbSwiper.params.control = mainSwiper;
// mismatch  between slide count means we need to do a special thing here

        // $('.fixed .thumb').click(function(){
        //   $('.thumb').removeClass('active');
        //   console.log($(this).index()+1);
        //   mainSwiper.slideTo( $(this).index() );
        // });

        $('.platform .toggle').click(function(e){        
            e.preventDefault();
        });

        $('.platform .toggle').mouseenter(function(){
          e.preventDefault();
          $('.consoles',this).fadeIn('fast');
        });

        $('.platform .toggle').mouseleave(function(){
          $('.consoles',this).fadeOut('fast');
        });

        $('.icon-search').click(function(){
          $(this).toggleClass('on');
          var sform = $('.search-form');

          $('.social').removeClass('open');
          $('.share-icons').fadeOut('fast');

          sform.parent().toggleClass('open');
          if( sform.parent().hasClass('open')){
            sform.fadeIn('fast');
          } else {
            sform.fadeOut('fast');
          }
        });

        $('.icon-share').click(function(){
          $(this).toggleClass('on');
          var sdiv = $('.share-icons');
          
          $('.search').removeClass('open');
          $('.search-form').fadeOut('fast');

          sdiv.parent().toggleClass('open');
          if( sdiv.parent().hasClass('open')){
            sdiv.fadeIn('fast');
          } else {
            sdiv.fadeOut('fast');
          }
        });

        // this needs work to get the right heights after page is fully loaded
        
          var scroller = $('.sidebar-left');

          if(scroller.length){
          var post = $('.entry-content'),
              postOffset = post.offset(),
              scrollerOffset = scroller.offset(),
              scrollerTop = scrollerOffset.top + scroller.outerHeight(),
              scrollerBottom = scrollerOffset.top + scroller.outerHeight(),
              postBottom = postOffset.top + post.outerHeight(),
              stack = 440; // temp

              scroller.height(post.outerHeight());
          }
            var scroll = window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || window.msRequestAnimationFrame || window.oRequestAnimationFrame || function(callback){ window.setTimeout(callback, 1000/60); };
            var scrolled = -1;
        

        function loop(){

          if (scrolled === window.pageYOffset) {

              scroll(loop);
              return false;

          } else {

              scrolled = window.pageYOffset;

              // parallax elements
              if(scrolled > 1){
                $('header.navbar').addClass('shrink');
              } else {
                $('header.navbar').removeClass('shrink');
              }

              if(scroller.length){
                if(scrolled >= scrollerTop-130){
                  $('.scroll', scroller).addClass('stick');
                } else {
                  $('.scroll', scroller).removeClass('stick');
                }
              }
                
              scroll( loop );
            }
        }

        loop();

        $('.post-navigation').onScreen({
           container: window,
           direction: 'vertical',
           doIn: function() {
             $('.sidebar-left .scroll').addClass('bottom');
           },
           doOut: function() {
             $('.sidebar-left .scroll').removeClass('bottom');
           },
           tolerance: 250,
           throttle: 50,
           toggleClass: 'onScreen',
           lazyAttr: null,
           debug: false
        });

        $('.gallery').magnificPopup({
          delegate: 'a',
          disableOn: 700,
          type: 'image',
          mainClass: 'mfp-fade',
          removalDelay: 160,
          preloader: false,
          fixedContentPos: false,
          closeBtnInside: false,
          gallery: {
            enabled: true,
            navigateByImgClick: true,
            preload: [0,1] // Will preload 0 - before current, and 1 after the current image
          },
        });

        $('.video-gallery').magnificPopup({
          disableOn: 700,
          type: 'iframe',
          mainClass: 'mfp-fade',
          removalDelay: 160,
          preloader: false,
          fixedContentPos: false,
          closeBtnInside: false,
          gallery: {
            enabled: true
          },
        });

        
          // responsive videos
        var $allVideos = $('iframe[src*="youtube.com"]'),
          $fluidEl = $("figure");

        $allVideos.each(function() {
          $(this).attr('data-aspectRatio', this.height / this.width).removeAttr('height').removeAttr('width');
        });

        $(window).resize(function() {

          //var newWidth = $fluidEl.width();
          $allVideos.each(function() {

            var $el = $(this);
            var newWidth = $el.parent().width();

            $el.width(newWidth).height(newWidth * $el.attr('data-aspectRatio'));

          });

        }).resize();
  
        

        
      },
      finalize: function() {}
    },

    'home': {
      init: function() {

        // resize facebook page plugin
        $( window ).resize(function() {
            var container_width = $('#fbContainer').width();
            //$('#fbContainer').html('<div class="fb-page" data-href="https://www.facebook.com/facebook" data-width="' + container_width + '" data-height="250" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true" data-show-posts="true"><div class="fb-xfbml-parse-ignore"><blockquote cite="https://www.facebook.com/facebook"><a href="https://www.facebook.com/facebook">Facebook</a></blockquote></div></div>');
            $('#fbContainer').html('<div class="fb-page" data-href="https://www.facebook.com/COGconnected" data-width="' + container_width + '" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true" data-show-posts="true"><div class="fb-xfbml-parse-ignore"><blockquote cite="https://www.facebook.com/GameCanadian"><a href="https://www.facebook.com/GameCanadian">Game Canadian</a></blockquote></div></div>');

            FB.XFBML.parse();    
        });

      },
      finalize: function() {}
    },
    
    'about_us': {
      init: function() {},
      finalize: function() {}
    },

    'single_video': {
      init: function() {
        $('.popup-video').magnificPopup({
          disableOn: 700,
          type: 'iframe',
          mainClass: 'mfp-fade',
          removalDelay: 160,
          preloader: false,
          fixedContentPos: false,
          closeBtnInside: false
        });
      },
      finalize: function() {}
    }
  };

  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {

      UTIL.fire('common');

      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      UTIL.fire('common', 'finalize');
    }
  };

  $(document).ready(UTIL.loadEvents);

})(jQuery);
